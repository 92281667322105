const BASE_URL = 'https://restcountries.com/v3.1/'

//  https://restcountries.com/v2/all?fields=

export const ALL_COUNTRIES = BASE_URL + 'all'

export const searchByCountry = (name) => BASE_URL + 'name/' + name

export const filterByCode = (codes) =>
  BASE_URL + 'alpha?codes=' + codes.join(',')

// const BASE_URL = 'https://restcountries.com/v3.1/'

// export const filterByCode = (codes) =>
//   BASE_URL + 'alpha?codes=' + codes.map((el) => `{${el}}`)
