import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Search } from './Search'
import { CustomSelect } from './CustomSelect'

// const options = [
//   { value: 'Africa', label: 'Africa' },
//   { value: 'America', label: 'America' },
//   { value: 'Asia', label: 'Asia' },
//   { value: 'Europe', label: 'Europa' },
//   { value: 'Oceania', label: 'Oceania' },
// ]

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 767px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const Controls = ({ onSearch, arrRegion }) => {
  const [search, setSearch] = useState('')
  const [region, setRegion] = useState('')

  // маємо список регіонів
  // перебрати та повернути масив об'єктів для випадаючого списку
  const option = arrRegion.map((el) => {
    return { value: el, label: el }
  })

  // useEffect має залежності search, region, коли ми змінюємо фільтр текту або випадіючий список, в нас спрацьовує useEffect, regionValue та як це обєкт, ми перевіряємо в region чи не пусте value, якщо пусте то '', далі ми передаєжмо search та regionValue в функцію onSearch наші значення search, regionValue.
  // функція onSearch це наш handleSource в HomePage

  useEffect(() => {
    const regionValue = region?.value || ''
    onSearch(search, regionValue)
    // eslint переживає, хоче щоб ми говорили що вона може змінитися, але ми знаємо що вона не змінитьтся
    // тому ми тупо відключимо наступну помилку
    // eslint-disable-next-line
  }, [search, region])

  return (
    <Wrapper>
      <Search search={search} setSearch={setSearch} />
      <CustomSelect
        options={option}
        placeholder='Filter by region'
        isClearable
        isSearchable={false}
        value={region}
        onChange={setRegion}
      />
    </Wrapper>
  )
}
