import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { filterByCode } from '../config'
import { Helmet } from 'react-helmet'
import { TfiNewWindow } from 'react-icons/tfi'

const Wrapper = styled.section`
  margin-top: 3rem;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  gap: 2rem;
  /* background: var(--colors-bg-gradient); */

  @media (min-width: 767px) {
    grid-template-columns: minmax(100px, 400px) 1fr; // зображення може займати від 100px до 400px, і праворуч 1 фрагмент
    align-items: center; // відносоно один одного по центру
    gap: 5rem; // відстань між ними по горизонталі
  }
  @media (min-width: 1024px) {
    grid-template-columns: minmax(400px, 600px) 1fr;
  }
`
const InfoImage = styled.img`
  display: block;
  width: 100%;
  max-height: 100%;
  object-fit: contain;

  box-shadow: var(--shadow2);
`
const InfoTitle = styled.h1`
  margin: 1rem 0;
  font-weight: var(--fw-normal);
`
const ListGroup = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 4rem;
  }
`
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const ListItem = styled.li`
  line-height: 1.8;

  & > b {
    font-weight: var(--fw-bold);
  }
`
const Meta = styled.div`
  margin-top: 3rem;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  align-items: flex-start;

  & > b {
    font-weight: var(--fw-bold);
  }

  @media (min-width: 767px) {
    flex-direction: row;
  }
`
const TagGroup = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`
const Tag = styled.span`
  padding: 0.33rem 1rem;
  background-color: var(--colors-ui-base);
  box-shadow: var(--shadow);
  line-height: 1.5;
  cursor: pointer;
`
const Link = styled.a`
  color: var(--colors-text);
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
`

function Info(props) {
  const navigate = useNavigate()

  const {
    name,
    flags,
    capital = [],
    population,
    region,
    subregion,
    borders = [],
    tld = [],
    languages = {},
    currencies = {},
    maps = {},
  } = props

  const [neighbors, setNeighbors] = useState([])

  useEffect(() => {
    if (borders.length) {
      axios
        .get(filterByCode(borders))
        .then(({ data }) => setNeighbors(data.map((c) => c.name.common)))
    }
  }, [borders])

  return (
    <Wrapper>
      <Helmet>
        <title>{name?.official}</title>
      </Helmet>

      <InfoImage src={flags.svg} alt={name.official} />
      <div>
        <InfoTitle>{name?.official}</InfoTitle>
        <ListGroup>
          <List>
            <ListItem>
              <b>Name:</b> {name.common}
            </ListItem>
            <ListItem>
              {/* https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat */}
              <b>Population:</b> {population.toLocaleString()}
              {/* {new Intl.NumberFormat('ru-RU').format(population)} */}
            </ListItem>
            <ListItem>
              <b>Region:</b> {region}
            </ListItem>
            <ListItem>
              <b>Sub Region:</b> {subregion}
            </ListItem>
            <ListItem>
              <b>Capital:</b>{' '}
              {capital.map((el) => (
                <span key={el}>{el}</span>
              ))}
            </ListItem>
          </List>
          <List>
            <ListItem>
              <b>Top Level Domain:</b>{' '}
              {tld.length ? (
                tld.map((el) => <span key={el}>{el + ' '}</span>)
              ) : (
                <span>-</span>
              )}
            </ListItem>
            <ListItem>
              <b>Currency:</b>{' '}
              {Object.keys(currencies).map((el) => (
                <span key={currencies[el]}>
                  {currencies[el]?.name + ', ' + currencies[el]?.symbol}
                </span>
              ))}
            </ListItem>
            <ListItem>
              <b>Languages:</b>{' '}
              {Object.keys(languages).map((el) => (
                <span key={languages[el]}>{languages[el] + ' '}</span>
              ))}
            </ListItem>
            <ListItem>
              <b>Maps:</b>{' '}
              {Object.keys(maps).map((el) => (
                <div key={maps[el]}>
                  <Link target='_blank' rel='noreferrer' href={maps[el]}>
                    {el[0].toUpperCase() + el.slice(1) + ' '} <TfiNewWindow />
                  </Link>
                </div>
              ))}
            </ListItem>
          </List>
        </ListGroup>

        <Meta>
          <b>Borders:</b>
          {!borders.length ? (
            <span>There is no border countries</span>
          ) : (
            <TagGroup>
              {neighbors.map((b) => (
                <Tag key={b} onClick={() => navigate(`/country/${b}`)}>
                  {b}
                </Tag>
              ))}
            </TagGroup>
          )}
        </Meta>
      </div>
    </Wrapper>
  )
}

export default Info
