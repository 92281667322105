import styled from 'styled-components'

const Wrapper = styled.article`
  border-radius: var(--border-radius);
  background-color: var(--colors-ui-base);
  box-shadow: var(--shadow);
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const CardImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const CardImage = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  box-sizing: border-box;
  padding: 10px;
`

const CardBody = styled.div`
  padding: 1rem 1.5rem 2rem;
`
const CardTitle = styled.h3`
  margin: 0;
  font-size: var(--fs-md);
  font-weight: var(-fw-bold);
`
const CardList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 1rem 0 0;
`
const CardListItem = styled.li`
  font-size: var(--fs-sm);
  line-height: 1.5;
  & > b {
    font-weight: var(-fw-bold);
  }
`

export const Card = ({ img, name, nameOfficial, info = [], onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <CardImageBox>
        <CardImage src={img} alt={name} />
      </CardImageBox>
      <CardBody>
        <CardTitle>{name}</CardTitle>
        <CardList>
          <CardListItem>
            <b>Official:</b> {nameOfficial}
          </CardListItem>
        </CardList>
        <CardList>
          {info.map((el) => (
            <CardListItem key={el.title}>
              <b>{el.title}:</b> {el.description}
            </CardListItem>
          ))}
        </CardList>
      </CardBody>
    </Wrapper>
  )
}
