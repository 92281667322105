import axios from 'axios'
import { useEffect, useState } from 'react'
import { ALL_COUNTRIES } from '../config'
import { List } from '../components/List'
import { Card } from '../components/Card'
import { Controls } from '../components/Controls'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const HomePage = ({ countries, setCountries }) => {
  // перенесли в app
  // const [countries, setCountries] = useState([])

  // Перебрати результат карток та вибрати з них регіони, потім вибрати унікальні
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
  // const numbers = [2, 3, 4, 4, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 5, 32, 3, 4, 5]
  // console.log([...new Set(numbers)])
  const arrRegion = [...new Set(countries.map((el) => el.region))]

  const [filtredCountries, setFiltredCountries] = useState(countries)

  // useNavogate дасть нам змогу пересуватися по сторінках
  // https://reactrouter.com/en/main/hooks/use-navigate
  const navigate = useNavigate()

  const handleSource = (search, region) => {
    // спредимо країни
    let data = [...countries]

    if (region) {
      // якщо в селекті вибради регіон, фільтруємо, беремо елемент, перевіряємо чи містить region значення region
      data = data.filter((c) => c.region.includes(region))
    }

    if (search) {
      // яаналогічно, перебрати країни, привести до нижнього регісьтру, якщо є то фільтрувати.
      data = data.filter((c) =>
        c.name.official.toLowerCase().includes(search.toLowerCase())
      )
    }

    // результат в стан
    setFiltredCountries(data)
  }

  // При першому рендері ми робимо запит країн
  useEffect(() => {
    if (!countries.length)
      // Якщо немає довжини, то робимо запит
      axios.get(ALL_COUNTRIES).then(({ data }) => setCountries(data))
    // eslint-disable-next-line
  }, [])

  // В цьому вмпадку в нас залежність від countries, якщо змінився набір країн, то визвати useEffect
  useEffect(() => {
    handleSource()
    // eslint-disable-next-line
  }, [countries])

  console.log(countries)

  return (
    <>
      <Helmet>
        <title>Countries Info - Countries API</title>
      </Helmet>
      <Controls onSearch={handleSource} arrRegion={arrRegion} />
      <List>
        {filtredCountries.map((el) => {
          // console.log(el)
          const countryInfo = {
            img: el.flags.svg,
            name: el.name.common,
            nameOfficial: el.name.official,
            info: [
              {
                title: 'Population',
                description: el.population.toLocaleString(),
              },
              {
                title: 'Region',
                description: el.region,
              },
              {
                title: 'Capital',
                description: el.capital,
              },
            ],
          }

          // console.log(countryInfo)

          return (
            <Card
              key={el.name.common}
              {...countryInfo}
              onClick={() => navigate(`/country/${el.name.common}`)}
            />
          )
        })}
      </List>
    </>
  )
}

export default HomePage
