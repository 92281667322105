import { styled } from 'styled-components'
import image404 from '../img/_6dd71a1d-6268-4214-a74e-b6dd7b77d146.jpg'
import { IoArrowForward } from 'react-icons/io5'

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #63fffe;
  img {
    object-fit: cover;
    height: 100vh;
  }

  h1 {
    max-width: 240px;
    position: fixed;
    z-index: 1;
    color: white;
    text-align: center;
  }

  a {
    position: fixed;
    z-index: 1;
    color: white;
    margin-top: 6rem;
  }
`

export const NotFound = () => {
  return (
    <Box>
      <img src={image404} alt='404 Page Not Found Error' />
      <h1>Error 404</h1>
      <a href='/'>
        Go to the start page <IoArrowForward size={12} />
      </a>
    </Box>
  )
}
