import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { NotFound } from './pages/NotFound'
import Root from './components/Root'
import HomePage from './pages/HomePage'
import { Details } from './pages/Details'
import { useState } from 'react'

// time 1.14.26

function App() {
  // маємо стан і пережаємо його в HomePage, там ми робимо запит до бекенда та передамл значення в стан
  // і перевіряємо чи є в нас довжина в useEffect, якщо є - то не потрібно робити запит за данними до бекенда
  const [countries, setCountries] = useState([])

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <NotFound />,
      children: [
        {
          path: '/',
          element: (
            <HomePage countries={countries} setCountries={setCountries} />
          ),
        },
        {
          path: '/country/:name',
          element: <Details />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}

export default App
