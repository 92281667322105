import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { searchByCountry } from '../config'
import Button from '../components/Button'
import Info from '../components/Info'
import styled from 'styled-components'

const Wrapper = styled.div``

export const Details = () => {
  // Дастати параметр зі cтроки
  // https://reactrouter.com/en/main/hooks/use-params
  let { name } = useParams()

  // Отримати useNavigate
  const navigate = useNavigate()
  // порверне на крок назад в історії браузера
  const goBack = () => navigate(-1)

  const [country, setCountry] = useState(null)

  console.log(country)

  useEffect(() => {
    // запит методом get через axios, на адресу через функцію searchByCountry з нашого config.js
    // коли запит виконається визветься .then(), ми отрммаємо об'єкт, де є вкладений ключ data
    // цей ключ ми положимо в setCountry з [0], так як нам приходить масив, бо працюэ як пошук, тому може прийти декілька... (тут краще переробити запит по унікальному ключу...)
    axios
      .get(searchByCountry(name))
      .then(({ data }) => setCountry(data[0]))
      .catch(function (error) {
        // console.log(error.response.status)
        if (error.response.status === 404) {
          navigate('/NotFound')
        }
      })
    // eslint-disable-next-line
  }, [name])

  // При завантадені педнятися вверх, завдяки useEffect
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToTop()
  }, [name])

  return (
    <Wrapper>
      <Button goBack={goBack} />
      {country && <Info {...country} />}
    </Wrapper>
  )
}
