import styled from 'styled-components'
import { IoArrowBack } from 'react-icons/io5'

const Mybutton = styled.button`
  background-color: var(--colors-ui-base);
  color: var(--colors-text);
  padding: 0 1rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  line-height: 2.5;
  border: none;
  cursor: pointer;
`

const Button = ({ goBack }) => {
  return (
    <Mybutton onClick={goBack}>
      <IoArrowBack /> Back
    </Mybutton>
  )
}

export default Button
