import ScrollToTopBtn from 'react-scroll-to-top'

const ScrollToTop = () => {
  return (
    <>
      <ScrollToTopBtn
        smooth
        width='1.2rem'
        height='1.2rem'
        color='grey'
        className='scrollToTopButton'
        style={{
          backgroundColor: 'var(--colors-bg)',
          border: '1px solid grey',
          opacity: '0.7',
          bottom: '100px',
        }}
      />
    </>
  )
}

export default ScrollToTop
