import styled from 'styled-components'

const Wrapper = styled.section`
  width: 100%;
  padding: 2rem 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;

  @media (min-width: 767px) {
    grid-template-columns: repeat(2, 2fr);
    gap: 2.5rem;
    padding: 2.5rem 0;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 4fr);
    gap: 3rem;
  }
`

export const List = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}
