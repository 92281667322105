// import Home from './Home'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import { Main } from './Main'
import Footer from './Footer'
import styled from 'styled-components'
import ScrollToTop from './ScrollToTop'

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const Box2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Root = () => {
  return (
    <Box>
      <Box2>
        <Header />
        <Main>
          <Outlet />
        </Main>
      </Box2>
      <Footer />
      {/* <ScrollingTop /> */}
      <ScrollToTop />
    </Box>
  )
}

export default Root
