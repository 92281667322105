import myLogo from '../myLogo_gr60.svg'
import styled from 'styled-components'

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0;
  padding: 1.2rem;
  background-color: var(--colors-ui-base);
  color: grey;

  img {
    max-width: 120px;
  }

  p {
    font-size: var(--fs-sm);
  }

  a {
    color: var(--colors-text);
    color: grey;
  }
`

const Footer = () => {
  return (
    <Wrapper>
      <a href='https://vyhivskyi.com'>
        <img src={myLogo} alt='[ vyhivskyi ]' />
      </a>
      <p>
        This project is for skill development. Thanks to MN. Link to task:{' '}
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.frontendmentor.io/challenges/rest-countries-api-with-color-theme-switcher-5cacc469fec04111f7b848ca'
        >
          REST Countries API with color theme switcher
        </a>
        , Thanks to{' '}
        <a target='_blank' rel='noreferrer' href='https://restcountries.com/'>
          API v3.1
        </a>
      </p>
    </Wrapper>
  )
}

export default Footer
